import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import TopCustomers from "./Pages/TopCustomers";
import MainMenu from "./Pages/MainMenu";
import CartProcess from "./Pages/CartProcess";
import SingleProduct from "./Pages/SingleProduct";
import AddressPage from "./Pages/AddressPage";
import CarDelivery from "./Pages/CarDelivery";
import Branches from "./Pages/Branches";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import ForgetPassword from "./Pages/ForgetPassword";
import ChangePass from "./Pages/ChangePass";
import Otp from "./Pages/Otp";
import CompanySales from "./Pages/CompanySales";
import AboutUs from "./Pages/AboutUs";
import Awafi from "./Pages/Awafi";
import ContactUs from "./Pages/ContactUs";
import Navbar from "./Components/Navbar";
import OrderTracking from "./Pages/OrderTracking";
import ClientCare from "./Pages/ClientCare";
import FromBranch from "./Pages/FromBranch";
import { useState, useEffect } from "react";
import Vat from "./Pages/Vat";
import Verify from "./Pages/Verify";
import FreeMeals from "./Pages/FreeMeals";
import Iso from "./Pages/Iso";
import { CategoryContext } from "./Context/CategoryContext";
import PaymentSuccess from "./Pages/PaymnetSuccess";
import { messaging } from "./Firebase/firebaseConfig";
import "./Firebase/firebaseConfig";
import { onMessage } from "firebase/messaging";
import Toast from "react-bootstrap/Toast";
import OrderNow from "./Pages/OrderNow";
import Transactions from "./Pages/Transactions";
import MyOrders from "./Pages/MyOrders";
import Address from "./Pages/MyAddresses";
import UserPoints from "./Pages/UserPoints";
import PersonalData from "./Pages/PersonalData";
import { Get_Basket } from "./api";
import MediaCenter from "./Pages/MediaCenter";
import CompaniesMenu from "./Pages/CompaniesMenu";
import { FaWindowRestore } from "react-icons/fa";
import CompanyInquire from "./Pages/CompanyInquire";
import { BeatLoader } from "react-spinners";
import Discount from "./Pages/Discount";
import { useNavigate } from "react-router-dom";
import ChargeWallet from "./Pages/ChargeWallet";
import WalletCharged from "./Pages/WalletCharged";
import UpdatePhone from "./Pages/UpdatePhone";
import Invoice from "./Pages/Invoice.js";
import PrivateRoutes from "./utils/PrivateRoutes";
import Promotions from "./Pages/Promotions";
import PromotionsBH from "./Pages/PromotionsBH";
import PaymentCheck from "./Pages/PaymentCheck";
import InvoiceSuccess from "./Pages/InvoiceSuccess";
import FailedTransaction from "./Pages/FailedTransaction";
import InvoiceCheck from "./Pages/InvoiceCheck";
import NotFound from "./Pages/NotFound";
import TaxInvoice from "./Pages/TaxInvoice";
import AccountType from "./Pages/AccountType";
import InvoicePdf from "./Pages/InvoicePdf";
import AlromansiahPlus from "./Pages/alromansiahplus";

function App() {
  const [country, setCountries] = useState(
    localStorage.getItem("country") ? localStorage.getItem("country") : 0
  );
  const [categ, setCateg] = useState();
  const [cartItems, setCartItem] = useState(0);
  const [cartChange, setCartChange] = useState(false);
  const [notify, setNotify] = useState(false);
  const [toast, setToast] = useState(false);
  const [cartId, setCartId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [process, setProcess] = useState(false);

  function handleMessage() {
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      setToast({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      setNotify(true);
      // ...
    });
  }
  handleMessage();

  /*
  window.webengage.onReady(function () {
    window.webengage.webpush.prompt()
    window.webengage.notification.render();
      var subscribed = window.webengage.webpush.isSubscribed();
  
      if (subscribed) {
          console.log('User is already subscribed');
      } else {
          console.log('User is not yet subscribed');
      }
    });*/

  useEffect(() => {
    const token = window.localStorage.getItem("token");
    if (token) {
      Get_Basket(token)
        .then((response) => {
          if (response.data.data.cart.meals) {
            localStorage.setItem("typeId", response.data.data.cart.typeId);
            setCartItem(response.data.data.cart.meals.length);
            setCartId(response.data.data.cart.id);
          } else {
            setCartItem(0);
          }
        })
        .catch((err) => {
          setCartItem(0);
        });
    }
  }, [cartChange, country]);

  function handleChangeLang() {
    setLoading(true);
    setTimeout(function () {
      setLoading(false);
    }, 500);
  }

  return (
    <div className="App">
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : null}
      <div style={loading ? { display: "none" } : null}>
        <BrowserRouter>
          <Navbar
            setProcess={setProcess}
            process={process}
            setCartItem={setCartItem}
            handleChangeLang={handleChangeLang}
            setCartChange={setCartChange}
            cartChange={cartChange}
            cartItems={cartItems}
            cartId={cartId}
            setCountries={setCountries}
            setLoading={setLoading}
          />

          <Toast
            onClose={() => setNotify(false)}
            show={notify}
            delay={4000}
            autohide
          >
            <Toast.Header>
              <p>{toast.title}</p>
            </Toast.Header>
            <Toast.Body>{toast.body}</Toast.Body>
          </Toast>

          <CategoryContext.Provider value={{ categ, setCateg }}>
            <Routes>
              <Route
                exact
                path="/promotions"
                element={<Promotions country={country} />}
              />
              <Route
                exact
                path="/promotions-bh"
                element={<PromotionsBH country={country} />}
              />
              <Route exact path="/invoice/:id" element={<Invoice />} />
              <Route exact path="/tax-invoice/:id" element={<InvoicePdf />} />

              <Route
                exact
                path="/"
                element={
                  <Home
                    cartId={cartId}
                    setCartItem={setCartItem}
                    country={country}
                    cartItems={cartItems}
                    cartChange={cartChange}
                    setCartChange={setCartChange}
                  />
                }
              />
              <Route exact path="/iso" element={<Iso />} />
              <Route
                exact
                path="/top-customers"
                element={<TopCustomers country={country} />}
              />
              <Route
                exact
                path="/branch"
                element={
                  <FromBranch
                    country={country}
                    cartId={cartId}
                    cartItems={cartItems}
                    cartChange={cartChange}
                    setCartChange={setCartChange}
                  />
                }
              />
              <Route
                exact
                path="/companies-menu/:id"
                element={<CompaniesMenu />}
              />
              <Route
                exact
                path="/companies-inquire/:id"
                element={<CompanyInquire setCartItem={setCartItem} />}
              />
              <Route
                exact
                path="/payment-check"
                element={<PaymentCheck country={country} />}
              />
              <Route
                exact
                path="/invoice-check/:id"
                element={<InvoiceCheck />}
              />

              <Route element={<PrivateRoutes />}>
                <Route
                  exact
                  path="/profile/myWallet"
                  element={
                    <Transactions country={country} setCartItem={setCartItem} />
                  }
                />
                <Route
                  exact
                  path="/profile/myOrders"
                  element={<MyOrders setCartItem={setCartItem} />}
                />
                <Route
                  exact
                  path="/profile/myAddresses"
                  element={
                    <Address country={country} setCartItem={setCartItem} />
                  }
                />
                <Route
                  exact
                  path="/profile/tax-invoice"
                  element={
                    <TaxInvoice country={country} setCartItem={setCartItem} />
                  }
                />
                <Route
                  exact
                  path="/profile/alromansiah-plus"
                  element={
                    <AlromansiahPlus
                      country={country}
                      setCartItem={setCartItem}
                    />
                  }
                />

                <Route
                  exact
                  path="/profile/account-type"
                  element={
                    <AccountType country={country} setCartItem={setCartItem} />
                  }
                />

                <Route
                  exact
                  path="/profile/myPoints"
                  element={<UserPoints setCartItem={setCartItem} />}
                />
                <Route
                  exact
                  path="/profile/myData"
                  element={<PersonalData setCartItem={setCartItem} />}
                />
                <Route
                  exact
                  path="/order-track/:id"
                  element={
                    <OrderTracking
                      setCartItem={setCartItem}
                      cartId={cartId}
                      cartItems={cartItems}
                      cartChange={cartChange}
                      setCartChange={setCartChange}
                    />
                  }
                />
                <Route
                  exact
                  path="/free-meals"
                  element={
                    <FreeMeals
                      cartChange={cartChange}
                      setCartChange={setCartChange}
                    />
                  }
                />
                <Route
                  exact
                  path="/discount"
                  element={
                    <Discount
                      cartChange={cartChange}
                      setCartChange={setCartChange}
                    />
                  }
                />
                <Route
                  exact
                  path="/charge-wallet"
                  element={
                    <ChargeWallet country={country} setCartItem={setCartItem} />
                  }
                />
                <Route
                  exact
                  path="/verify-phone"
                  element={<UpdatePhone setCartItem={setCartItem} />}
                />
                <Route
                  exact
                  path="/payment-success"
                  element={<PaymentSuccess setCartItem={setCartItem} />}
                />
                <Route
                  exact
                  path="/payment-fail"
                  element={<FailedTransaction />}
                />

                <Route
                  exact
                  path="/walletCharged"
                  element={<WalletCharged />}
                />
                <Route
                  exact
                  path="/address"
                  element={
                    <AddressPage country={country} setCartItem={setCartItem} />
                  }
                />
              </Route>

              <Route exact path="/news/:postId" element={<MediaCenter />} />
              <Route exact path="/menu" element={<MainMenu />} />
              <Route
                exact
                path="/meals/:id"
                element={
                  <SingleProduct
                    cartChange={cartChange}
                    setCartItem={setCartItem}
                    setCartChange={setCartChange}
                  />
                }
              />
              <Route
                exact
                path="/order-now"
                element={
                  <OrderNow
                    cartId={cartId}
                    cartItems={cartItems}
                    cartChange={cartChange}
                    setCartChange={setCartChange}
                  />
                }
              />
              <Route
                exact
                path="/cart"
                element={
                  <CartProcess
                    setProcess={setProcess}
                    process={process}
                    cartChange={cartChange}
                    setCartItem={setCartItem}
                    setCartChange={setCartChange}
                  />
                }
              />

              <Route exact path="/client-care" element={<ClientCare />} />
              <Route exact path="/car" element={<CarDelivery />} />
              <Route
                exact
                path="/branches"
                element={<Branches country={country} />}
              />
              <Route exact path="/vat" element={<Vat />} />
              <Route path="*" element={<NotFound />} />

              <Route
                exact
                path="/login"
                element={
                  <Login
                    cartChange={cartChange}
                    setCartChange={setCartChange}
                  />
                }
              />
              <Route
                exact
                path="/invoice-success"
                element={<InvoiceSuccess />}
              />

              <Route exact path="/register" element={<Register />} />
              <Route exact path="/forget" element={<ForgetPassword />} />
              <Route exact path="/changePassword" element={<ChangePass />} />
              <Route
                exact
                path="/company-sales"
                element={<CompanySales country={country} />}
              />
              <Route
                exact
                path="/about-us"
                element={<AboutUs country={country} />}
              />
              <Route
                exact
                path="/code"
                element={
                  <Otp cartChange={cartChange} setCartChange={setCartChange} />
                }
              />
              <Route
                exact
                path="/verify"
                element={
                  <Verify
                    cartChange={cartChange}
                    setCartChange={setCartChange}
                  />
                }
              />
              <Route exact path="/awafi" element={<Awafi />} />
              <Route
                exact
                path="/contact-us"
                element={<ContactUs country={country} />}
              />
            </Routes>
          </CategoryContext.Provider>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
