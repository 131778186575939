import React, { useState, useEffect } from "react";
import "../Styles/Home.css";
import poster from "../Images/cstmrIcons/poster.png";
import { BiSearch } from "react-icons/bi";
import Navbar from "../Components/Navbar";
import Logof from "../Images/logof.svg";
import Circle from "../Images/Mask Group.svg";
import decore from "../Images/brown-decore.svg";
import certified from "../Images/certified.png";
import community from "../Images/community.svg";
import communityDecore from "../Images/community-footer.svg";
import { useTranslation } from "react-i18next";
import certifiedN from "../Images/certified1.png";
import com from "../Images/comm.png";

import certified2 from "../Images/certified2.png";

import download from "../Images/download.jpg";

/*import t1 from "../Images/home.svg"
import t2 from "../Images/car.svg"
import t3 from "../Images/branch.svg"*/

import t2 from "../Images/ot2.jpeg";
import t3 from "../Images/ot3.svg";
import t1 from "../Images/ot1.svg";
import t4 from "../Images/ot4.svg";

/*
import t1 from "../Images/type1.svg"
import t2 from "../Images/homeDelivery.png"
import t3 from "../Images/carDelivery.png"*/
import { CustomerInfo } from "../api";
import { BeatLoader } from "react-spinners";
import s1 from "../Images/step1.svg";
import dts from "../Images/dots.svg";
import crcl from "../Images/Ellipse 494.svg";
import div5 from "../Images/mobile.svg";
import ag from "../Images/AppGallery.svg";
import as from "../Images/AppStore.svg";
import gp from "../Images/GooglePlay.svg";
import agb from "../Images/AppGalleryb.svg";
import asb from "../Images/AppStoreb.svg";
import gpb from "../Images/GooglePlayb.svg";
import inside from "../Video/landingPage.mp4";
import fc from "../Images/fcircle.svg";
import { AiOutlineTwitter } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import { BsSnapchat } from "react-icons/bs";
import { AiFillYoutube } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import TypeList from "../Components/TypeList";
import ChangeType from "../Components/ChangeType";
import { getAnalytics, logEvent } from "firebase/analytics";

//import { analytics } from "../Firebase/firebaseConfig";
//import { firebaseAnalytics } from "../Firebase/firebaseConfig";
import { app } from "../Firebase/firebaseConfig";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import Slider from "../Components/Slider";
import i18next from "i18next";
import useGeoLocation from "../Components/useGeoLocation";
import {
  near_branches,
  countries,
  Clear_cart,
  Banners,
  getTypes,
} from "../api";
import CountriesModal from "../Components/CountriesModal";
function Home(props) {
  const analytics = getAnalytics(app);
  const { t, i18n } = useTranslation();
  const location = useGeoLocation();
  const history = useNavigate();
  const [changeModal, setChangeModal] = useState(false);
  const [countryModal, setCountryModal] = useState(false);
  const [country, setCountry] = useState([]);
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(true);
  const [navigate, setNavigate] = useState();
  const [cartItems, setCartItems] = useState(0);
  const [banners, setBanners] = useState([]);
  const [types, setTypes] = useState([]);

  //fetch slider images
  //fetch customer phone (for use in webengage and analytics)
  useEffect(() => {
    setLoading(true);
    localStorage.setItem("prevScreen", "home");
    const token = localStorage.getItem("token");
    window.scrollTo(0, 0);

    let language = i18n.language == "ar" ? "ar" : "en";
    let countryId = props.country == 0 ? 1 : 2;

    Banners("website", language, countryId).then((response) => {
      const data = response.data.data;
      setBanners(data);
    });

    //logEvent(analytics, 'start');
    if (token) {
      CustomerInfo(token)
        .then((response) => {
          setPhone(response.data.data.phone.toString());
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [i18n.language, props.country]);

  useEffect(() => {
    getTypes().then((res) => {
      const types = res.data.data.slice(0, 4);

      setTypes(types);
    });
    console.log(types);
  }, []);

  /*first checks if cart is not empty and user hasn't already chosen an order type or has chosen a different order type
 => a modal is displayed (ChangeType component found in /Components) for user to confirm clearing the cart and changing order type */

  /*else checks if the custom hoook useGeoLocation is given permission to get user lat and lng
if yes near_branches api is called to fetch near branches using user location and will store near branches then user is redirected to /branches screen (FromBranch component found in /Pages)*/

  /*if there are no near branches user is also redirected to /branches screen and nearbranches are set to an empty array*/
  function handleBranches() {
    const token = localStorage.getItem("token");
    if (token) {
      logEvent(analytics, "click_branch", {
        phone: phone,
      });
    }
    let typeId = localStorage.getItem("typeId");
    if (token && props.cartItems !== 0 && typeId && parseInt(typeId) !== 1) {
      setChangeModal(true);
      setNavigate("/branch"); //for handleClear function to know which path to redirect to
    } else {
      localStorage.setItem("typeId", 1);
      if (location.loaded && location.coordinates) {
        near_branches(location.coordinates.lat, location.coordinates.lng, 'branch').then(
          (response) => {
            localStorage.setItem(
              "nearBranches",
              JSON.stringify(response.data.data)
            );
            localStorage.setItem("addressLat", location.coordinates.lat);
            localStorage.setItem("addressLong", location.coordinates.lng);

            history("/branch");
          }
        );
      } else {
        history("/branch");
        localStorage.setItem("nearBranches", JSON.stringify([]));
      }
    }
  }

  const handleDonation = () => {
    localStorage.setItem("typeId", "3");

    if (props.cartItems !== 0) {
      setChangeModal(true);
      localStorage.setItem("donationMode", "true");
      localStorage.setItem("branchId", "34");

      setNavigate("/menu");
    } else {
      localStorage.setItem("donationMode", "true");
      localStorage.setItem("branchId", "34");
      localStorage.setItem("typeId", "3");

      history("/menu");
    }
  };

  /*first checks if cart is not empty and user hasn't already chosen an order type or has chosen a different order type
 => a modal is displayed (ChangeType component found in /Components) for user to confirm clearing the cart and changing order type */

  /*else  user is  redirected to /address screen (AddressPage found in /Pages)*/
  /*if user is not logged in user is redirected to login screen*/
  function handleDelivery() {
    const token = localStorage.getItem("token");
    if (token) {
      let typeId = localStorage.getItem("typeId");
      if (token && props.cartItems !== 0 && typeId && parseInt(typeId) !== 2) {
        setChangeModal(true);
        setNavigate("/address"); //for handleClear function to know which path to redirect to
      } else {
        history("/address");
      }

      logEvent(analytics, "click_delivery", {
        phone: phone,
      });
      /*
  window.webengage.track("Click Delivery", {
  
    phone: phone
  })*/
    } else {
      history("/login");
    }
  }
  /*first checks if cart is not empty and user hasn't already chosen an order type or has chosen a different order type
 => a modal is displayed (ChangeType component found in /Components) for user to confirm clearing the cart and changing order type */

  /*else  user is  redirected to /car screen (CarDelivery found in /Pages)*/

  function handleCarDelivery() {
    const token = localStorage.getItem("token");
    let typeId = localStorage.getItem("typeId");
    console.log(props.cartItems);

    if (token && props.cartItems !== 0 && typeId && parseInt(typeId) !== 4) {
      setChangeModal(true);
      setNavigate("/car"); //for handleClear function to know which path to redirect to
    } else {
      history("/car");
    }
  }

  //on confirming clearing cart (from ChangeType modal)
  //user cart is cleared
  function handleClear() {
    setLoading(true);

    const token = window.localStorage.getItem("token");

    Clear_cart(props.cartId, token)
      .then((response) => {
        localStorage.removeItem("promo"); //was used to store data that user entered in cart
        localStorage.removeItem("paymentMethod"); //was used to store data that user entered in cart
        localStorage.removeItem("offer");
        //data stored in cartProcess2
        localStorage.removeItem("timeNow");
        localStorage.removeItem("cartMessage");
        localStorage.removeItem("orderDate");
        localStorage.removeItem("deliveryType");
        localStorage.removeItem("cardToken");
        localStorage.setItem("donationMode", false);
        localStorage.setItem("process", 0); //was used to store user's current step in cart, set to 0 to begin steps from the start
        if (navigate === "/menu") {
          localStorage.setItem("typeId", "3");
        } else {
          localStorage.setItem("typeId", navigate === "/branch" ? "1" : "2");
        }
        props.setCartChange(!props.cartChange);

        if (navigate == "/branch") {
          if (location.loaded && location.coordinates) {
            near_branches(
              location.coordinates.lat,
              location.coordinates.lng,
              'branch'
            ).then((response) => {
              localStorage.setItem(
                "nearBranches",
                JSON.stringify(response.data.data)
              );
              localStorage.setItem("addressLat", location.coordinates.lat);
              localStorage.setItem("addressLong", location.coordinates.lng);

              history("/branch");
            });
          } else {
            localStorage.setItem("nearBranches", JSON.stringify([]));

            history("/branch");
          }
        } else {
          history(navigate);
        }

        //  props.setCartChange(!props.cartChange) //triggers api call in (App.js) to rerender cart
        // setLoading(false)
      })
      .catch((err) => {
        if (err.response.status === 401) {
          //used to change cart icon's current value to 0

          props.setCartItem(0);

          localStorage.removeItem("token");
          localStorage.removeItem("userPhone");
          localStorage.removeItem("userName");
          localStorage.removeItem("basket");
          //was used to store user's current step in cart process
          localStorage.removeItem("process");

          history("/login");
        } else if (err.response.status === 422) {
          history(navigate);
        }
      });
  }

  function handleAction(id) {
    switch (id) {
      case 1:
        handleBranches();
        break;
      case 2:
        handleDelivery();
        break;
      case 3:
        handleDonation();
        break;
      default:
        break;
    }
  }
  function getImage(id) {
    switch (id) {
      case 1:
        return t1;
      case 2:
        return t2;
      case 3:
        return t4;
      case 4:
        return t3;
      default:
        return "";
    }
  }
  function getTypeName(id) {
    switch (id) {
      case 1:
        return t("home.option-branch");
      case 2:
        return t("home.option-home");
      case 3:
        return t("home.option-donation");
      case 4:
        return t("home.option-car");
      default:
        return "";
    }
  }

  return (
    <div className="Home">
      {countryModal ? (
        <CountriesModal country={country} setModal={setCountryModal} />
      ) : null}
      {changeModal ? (
        <ChangeType
          text="change_type"
          handleClear={handleClear}
          navigate={navigate}
          setModal={setChangeModal}
        />
      ) : null}
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <>
          <div>
            <Slider banners={banners} type="website" />
          </div>
          <div className="thirdDiv container-fluid fluid firstDiv">
            <h1 className="how">{t("home.how")}</h1>
            <h1 className="how-detail"> {t("home.how-detail")}</h1>

            <div style={{ paddingBottom: "10px", marginTop: "2em" }}>
              <div className="row gy-5">
                {types.map(
                  (type, index) =>
                    type.visible && (
                      <div
                        onClick={() => handleAction(type.id)}
                        key={type.id}
                        className={`col-md-${Math.floor(12 / types.filter(type => type.visible).length)} col-sm-6`}

                      >
                        <div
                          className="menu"
                          style={{
                            cursor: type.isActive ? "pointer" : "not-allowed",
                            pointerEvents: type.isActive ? "auto" : "none",
                          }}
                        >
                          {!type.isActive && (
                            <p
                              className={
                                i18n.language === "en" ? "soon soonEn" : "soon"
                              }
                            >
                              {t("home.soon")}
                            </p>
                          )}
                          <div
                            className="menueItem"
                            style={{
                              backgroundImage: `url(${getImage(type.id)})`,
                              position: "relative",
                              top: type.id === 2 ? "70px" : "auto",
                            }}
                          >
                            {!type.isActive && <div id="layer"></div>}
                          </div>
                          <div
                            className="menubottom"
                            style={{ position: "relative" }}
                          >
                            {!type.isActive && <div id="layer2"></div>}
                            <button
                              disabled={!type.isActive}
                              style={{
                                height: "75px",
                                cursor: type.isActive
                                  ? "pointer"
                                  : "not-allowed",
                              }}
                              className={
                                i18n.language === "en"
                                  ? `${
                                      type.isActive
                                        ? "enArrow-button2"
                                        : "enArrow-button2 btnDisabled"
                                    } arrow-button2`
                                  : `${
                                      type.isActive ? "" : "btnDisabled"
                                    } arrow-button2`
                              }
                            >
                              {getTypeName(type.id)}
                              <span className="arrow2"></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                )}

                {/* make the col-md-4 col-sm-6 to col-md-3 col-sm-6  */}
                {/* <div className="col-md-3 col-sm-6">
                  <div
                    className="menu"
                    disabled
                    style={{ cursor: "not-allowed", pointerEvents: "none" }}
                  >
                    <p
                      className={i18n.language == "en" ? "soon soonEn" : "soon"}
                    >
                      {t("home.soon")}
                    </p>
                    <div
                      className="menueItem"
                      style={{
                        backgroundImage: `url(${t3})`,
                        position: "relative",
                      }}
                    >
                      <div id="layer"></div>
                    </div>

                    <div
                      className="menubottom"
                      style={{ position: "relative" }}
                    >
                      <div id="layer2"></div>

                      <button
                        disabled
                        style={{ height: "75px", cursor: "not-allowed" }}
                        className={
                          i18n.language == "en"
                            ? "enArrow-button2 btnDisabled arrow-button2"
                            : " btnDisabled arrow-button2"
                        }
                      >
                        {t("home.option-car")}
                        <span className="arrow2"></span>
                      </button>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div
            className="thirdDiv  container-fluid fluid"
            style={{ marginTop: "0" }}
          >
            <h1 className="how">{t("home.inside")}</h1>
            <h1 className="how-detail"> {t("home.inside-detail")}</h1>

            <div style={{ paddingBottom: "10px", marginTop: "2em" }}>
              {/* <video width="100%" controls poster={poster} >
                                     <source src={inside} type="video/mp4"/>
</video>*/}
              <iframe
                width="100%"
                height="500px"
                src="https://www.youtube.com/embed/NqIAOBgiokw"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div>
            <div className="container-fluid fluid certifyDiv">
              <div className="row gy-4">
                <div
                  style={{ position: "relative" }}
                  className="col-md-4 certifiedLogo"
                >
                  <div
                    className="div5img"
                    style={{
                      backgroundImage: `url(${certifiedN})`,
                      height: "unset",
                    }}
                  ></div>
                  <div
                    className="div5img"
                    style={{
                      backgroundImage: `url(${certified2})`,
                      height: "unset",
                    }}
                  ></div>
                </div>

                <div
                  style={{ position: "relative" }}
                  className="col-md-8 div5-2"
                >
                  <div className="certified">
                    <div>
                      <p className="title-2">{t("iso.iso")}</p>
                      <p className="title2-2">{t("iso.iso1")}</p>
                      <Link to="/iso">
                        <button>{t("home.more")}</button>
                      </Link>
                    </div>
                  </div>
                  <img
                    src={decore}
                    className={
                      i18n.language == "ar"
                        ? "decoration"
                        : "decoration decorationEn"
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="thirdDiv container-fluid fluid">
            <div className="row community" style={{ margin: "auto" }}>
              <div className="col-md-6 comm-image ">
                <div
                  className="div5img "
                  style={{
                    backgroundImage: `url(${com})`,
                    width: "100%",
                    height: "190px",
                  }}
                ></div>
              </div>

              <div
                style={{ position: "relative", marginTop: "0" }}
                className="col-md-6 com-text"
              >
                <p className="com-title"> {t("home.social")}</p>
                <p className="com-details">{t("home.social-detail")}</p>
                {/*
<button className={i18n.language=="ar"?"arrow-button2":"arrow-button2 enArrow-button2"}>{t("home.more")}<span className="arrow2"></span>
</button>*/}
                <img
                  src={communityDecore}
                  className={
                    i18n.language == "ar"
                      ? "comm-footer"
                      : "comm-footer comm-footerEn"
                  }
                />
              </div>
            </div>
          </div>

          <div>
            <div className="container-fluid fluid bottomDiv">
              <div className="row gy-4">
                <div style={{ marginTop: "0" }} className="col-md-7  div5-3">
                  <div>
                    <p
                      className={
                        i18n.language == "ar" ? "title-5" : "title-5 title-5En"
                      }
                    >
                      {t("home.provided")}
                    </p>
                    {/*
 <div style={{alignItems:"center"}} className="div5Buttons row">
   <div className="col-lg-3 col-md-12 col-sm-3 col-12">
 <p style={{marginBottom:"0"}} className="title2-5"> {t("home.provided-deatils")}</p>
 </div> 
   <div className="col-lg-3 col-md-4 col-sm-3 col-4">
<a href="https://play.google.com/store/apps/details?id=com.tech_world.romansia&hl=ar" target="_blank">
 <button style= {{backgroundImage: `url(${gp})`}}  className="btn5"></button>
 
 </a></div>
 <div className="col-lg-3 col-md-4 col-sm-3 col-4">
 <a href="https://apps.apple.com/sa/app/%D8%A7%D9%84%D8%B1%D9%88%D9%85%D8%A7%D9%86%D8%B3%D9%8A%D8%A9/id680481004" target="_blank">
 <button style= {{backgroundImage: `url(${as})`}}  className="btn5"></button>
 </a>
 </div>
 <div className="col-lg-3 col-md-4 col-sm-3 col-4">
 <a href="https://appgallery.huawei.com/app/C103513027" target="_blank">
 <button style= {{backgroundImage: `url(${ag})`}} className="btn5"></button>
 </a></div>
</div>*/}
                    <p style={{ marginBottom: "3em" }} className="title2-5">
                      {" "}
                      {t("home.provided-deatils")}
                    </p>
                    {/*<div className="container-fluid" style={i18n.language=="ar"?{paddingRight:"0"}:{paddingLeft:"0"}}>
 <div style={{alignItems:"center"}} className="div5Buttons row">
  
   <div className="col-4">
<a href="https://play.google.com/store/apps/details?id=com.tech_world.romansia&hl=ar" target="_blank">
 <button style= {{backgroundImage: `url(${gp})`}}  className="btn5"></button>
 
 </a></div>
 <div className="col-4">
 <a href="https://apps.apple.com/sa/app/%D8%A7%D9%84%D8%B1%D9%88%D9%85%D8%A7%D9%86%D8%B3%D9%8A%D8%A9/id680481004" target="_blank">
 <button style= {{backgroundImage: `url(${as})`}}  className="btn5"></button>
 </a>
 </div>
 <div className="col-4">
 <a href="https://appgallery.huawei.com/app/C103513027" target="_blank">
 <button style= {{backgroundImage: `url(${ag})`}} className="btn5"></button>
 </a></div>
</div></div>*/}

                    <div
                      style={{ alignItems: "center" }}
                      className="div5Buttons "
                    >
                      <div>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.tech_world.romansia&hl=ar"
                          target="_blank"
                        >
                          <button
                            style={{ backgroundImage: `url(${gp})` }}
                            className="btn5"
                          ></button>
                        </a>
                      </div>
                      <span></span>
                      <div>
                        <a
                          href="https://apps.apple.com/sa/app/%D8%A7%D9%84%D8%B1%D9%88%D9%85%D8%A7%D9%86%D8%B3%D9%8A%D8%A9/id680481004"
                          target="_blank"
                        >
                          <button
                            style={{ backgroundImage: `url(${as})` }}
                            className="btn5"
                          ></button>
                        </a>
                      </div>
                      <span></span>
                      <div>
                        <a
                          href="https://appgallery.huawei.com/app/C103513027"
                          target="_blank"
                        >
                          <button
                            style={{ backgroundImage: `url(${ag})` }}
                            className="btn5"
                          ></button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{ position: "relative" }}
                  className="col-md-5 div5-1 order-first order-md-last"
                >
                  <div
                    className="div5img appImg"
                    style={{
                      backgroundImage: `url(${div5})`,
                      width: "100%",
                      height: "400px",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}

export default Home;
