import React, { useState, useEffect } from "react";
import invoiceImg from "../Images/invoice.png";
import { BeatLoader } from "react-spinners";
import Footer from "../Components/Footer";
import { useTranslation } from "react-i18next";
import { invoiceData } from "../api";
import QRCode from "react-qr-code";

function Invoice() {
  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState(false);
  const { t, i18n } = useTranslation();

  const orderId = window.location.href.split("/")[4].split("-")[0];
  const orderCode = window.location.href.split("/")[4].split("-")[1];

  useEffect(() => {
    invoiceData(orderId, orderCode).then((response) => {
      setInvoice(response.data.data);
      setLoading(false);
    });
  }, []);

  const typeId = invoice.typeId
  const paymentOnlineType = invoice.paymentOnlineType != "checkout"
  const readyToPickUp = parseInt(invoice.typeId) !=2 && invoice.statusId==2 || invoice.statusId == 1
  const picked = parseInt(invoice.typeId) !=2 && parseInt(invoice.statusId)==4

  console.log(readyToPickUp)
  console.log(picked)

  
  

  return (
    <div>
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <div className="container-fluid fluid"  >
          <div  className="vat invoiceDiv" style={{ marginBottom: "5em" , backgroundColor : readyToPickUp ? '#fff2cb' : picked ?'#d8ead2' :"white"  }}>
            <p className="invoiceTitle">
              {invoice.invoiceQR !== null
                ? " فاتورة ضريبية مبسطة"
                : "مسودة فاتورة"}
            </p>
            <div
              className="invoiveLogo"
              style={{ backgroundImage: `url(${invoiceImg})`, }}
            ></div>
            <div className="invoiceSecondTitle">
              <p>شركة الرومانسية مساهمة مقفلة</p>
              <p>الادارة العامة - الرياض - حي السلام - ت -2390770</p>
              <p style={{ direction: "rtl" }}>
                {" "}
                {invoice.branchArName} - {invoice.invoiceAddress}{" "}
              </p>
              <p>هاتف - {invoice.branchPhone}</p>
            </div>

            <div className="invoiceData" style={{ direction: "rtl" }}>
              <div>
                <p>الرقم الضريبي</p>
                <p className="invoice-value">{invoice.taxNumber}</p>
              </div>

              <div>
                <p>السجل التجاري</p>
                <p className="invoice-value">{invoice.commercialRegister}</p>
              </div>
              {invoice.orderCode == null ? null : (
                <div>
                  <p> رمز الفاتورة</p>
                  <p className="invoice-value">{invoice.orderCode}</p>
                </div>
              )}

              <div>
                <p> الرمز المرجعي</p>
                <p className="invoice-value">{orderId}</p>
              </div>
              {invoice.now == false ? (
                <>
                  <div>
                    <p> التاريخ</p>
                    <p className="invoice-value">{invoice.date}</p>
                  </div>
                  <div>
                    <p> الوقت</p>
                    <p className="invoice-value">{invoice.time}</p>
                  </div>
                </>
              ) : null}

              <div>
                <p>تاريخ الحجز</p>
                <p className="invoice-value">{invoice.createdAt}</p>
              </div>
              <div>
                <p> مصدر الطلبية</p>
                <p className="invoice-value">{invoice && invoice.platform}</p>
              </div>

              <div>
                <p>نوع الدفع</p>
                <p className="invoice-value">
                  {invoice && invoice.paymentMethod}
                </p>
              </div>
              <div>
                <p>العميل</p>
                <p className="invoice-value">{invoice.userName}</p>
              </div>
              <div>
                <p> الجوال</p>
                <p className="invoice-value">{invoice.userPhone}</p>
              </div>

              {invoice.notes !== "" ? (
                <div>
                  <p>ملاحظات</p>
                  <p
                    className="invoice-value"
                    style={{
                      maxWidth: "220px",
                      lineHeight: "24px",
                      textAlign: "start",
                    }}
                  >
                    {invoice.notes}
                  </p>
                </div>
              ) : null}
            </div>

            <div
              style={{ direction: "rtl" }}
              className="upTable invoiceTableDiv "
            >
              <table className="invoiceTable table  align-middle table-striped table-hover table-borderless ">
                <thead>
                  <tr>
                    <th scope="col" colSpan={2}>
                      الوجبة
                    </th>
                    <th scope="col">الكمية</th>
                    <th scope="col">السعر</th>
                  </tr>
                </thead>
                <tbody>
                  {invoice.products &&
                    invoice.products.map((data, index) => (
                      <tr>
                        <td colSpan={2}>
                          {data.productArName} {" - " + data.sizeArName}
                          {data.additionals.length !== 0
                            ? " - " +
                              data.additionals.map(
                                (item) => item.additionalArName
                              )
                            : " "}
                          {data.extras.length !== 0
                            ? " - " +
                              data.extras.map((item) => item.arName + " ")
                            : "  "}
                        </td>

                        <td>{data.quantity}</td>
                        <td>{data.price}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <div
              className="invoiceData"
              style={{ padding: "1em 0", direction: "rtl" }}
            >
              <div>
                <p>السعر</p>
                <p className="invoice-value">
                  {invoice.originalPrice.toFixed(1)} {invoice.currencyAr}
                </p>
              </div>

              {invoice.discount > 0 ? (
                <div>
                  <p>الخصم</p>
                  <p className="invoice-value">
                    {parseFloat(invoice.discount).toFixed(1)}{" "}
                    {invoice.currencyAr}
                  </p>
                </div>
              ) : null}
              <div>
                <p>{"ضريبة" + " " + `(${invoice.tax}%)`}</p>
                <p className="invoice-value">
                  {invoice.taxValue} {invoice.currencyAr}
                </p>
              </div>

              {invoice.typeId == 2 ? (
                <div>
                  <p>رسوم التوصيل</p>
                  <p className="invoice-value">
                    {invoice.deliveryPrice}{" "}
                    {i18n.language == "ar"
                      ? invoice.currencyAr
                      : invoice.currencyEn}
                  </p>
                </div>
              ) : null}

              {invoice.walletPay > 0 ? (
                <div>
                  <p>المدفوع من المحفظة</p>
                  <p className="invoice-value">
                    {invoice.walletPay}{" "}
                    {i18n.language == "ar"
                      ? invoice.currencyAr
                      : invoice.currencyEn}
                  </p>
                </div>
              ) : null}

              <div>
                <p className="invoiceTotal1">المجموع الكلي </p>
                <p className="invoiceTotal2">
                  {invoice.totalPrice.toFixed(1)} {invoice.currencyAr}
                </p>
              </div>
            </div>

            {invoice.invoiceQR !== null ? (
              <QRCode
                style={{
                  height: "auto",
                  maxWidth: "100%",
                  width: "100%",
                  marginTop: "1em ",
                }}
                value={invoice.invoiceQR}
                viewBox={`0 0 256 256`}
              />
            ) : (
              <div className="caution" style={{ direction: "rtl" }}>
                <p className="invoiceTotal2" style={{ marginBottom: "0" }}>
                  ملاحظة
                </p>
                <p className="invoiceTotal1">
                  الفاتورة الضريبية ستكون مع المندوب او عند استلام الطلب من
                  الفرع
                </p>
              </div>
            )}
          </div>
        </div>
      )}{" "}
      <Footer />
    </div>
  );
}

export default Invoice;

