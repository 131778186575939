import React, { useEffect, useState } from "react";

import success from "../Images/success-web.svg";
import failed from "../Images/faild-web.svg";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import { CustomerInfo, payAgain } from "../api";
import { getAnalytics, logEvent } from "firebase/analytics";
import wallet from "../Images/wallet.svg";
import { app } from "../Firebase/firebaseConfig";
import Footer from "../Components/Footer";
import BeatLoader from "react-spinners/BeatLoader";
import { useNavigate } from "react-router-dom";
import { payment, pay_again, order_track } from "../api";
import { Link } from "react-router-dom";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";
import { payAgain_checkout } from "../api";
import { checkPayment, checkPaymentToken } from "../api";
import { DotLoader } from "react-spinners";
function PaymentCheck(props) {
  const { t, i18n } = useTranslation();
  const [cardValid, setCardValid] = useState(false);
  const [msgAr, setMsgAr] = useState();
  const [msgEn, setMsgEn] = useState();
  const [errorMsgModel, setErrorMsgModel] = useState(false);
  const [warning, setWaringing] = useState(false);
  const [arErr, setArrErr] = useState("");
  const [enErr, setEnrErr] = useState("");
  const [authorized, setAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const typeid = parseInt(localStorage.getItem("typeId"));

  const [state, setState] = useState(false);
  const paymentType = localStorage.getItem("checkPayment")
    ? localStorage.getItem("checkPayment")
    : "order";

  const [paymentLoad, setPaymentLoad] = useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let sessionId = params.get("cko-session-id");
  const token = localStorage.getItem("token");
  let history = useNavigate();

  let navigate = useNavigate();

  const checkPaymentStatus = (attempt = 0) => {
    if (attempt >= 10) {
      setAuthorized(false);
      return;
    }

    const currencyCode = localStorage.getItem("currencyCode") || "SAR";
    const transactionType = localStorage.getItem("transactionType") || "order";
    const paymentCheckFunction = token ? checkPaymentToken : checkPayment;
    const paymentCheckArgs = token
      ? [token, sessionId, currencyCode, transactionType]
      : [sessionId, currencyCode, transactionType];

    paymentCheckFunction(...paymentCheckArgs)
      .then((response) => {
        if (
          response.data.data.status === "Authorized" ||
          response.data.data.error === "Authorized"
        ) {
          if (
            transactionType == "subscription" ||
            transactionType == "wallet"
          ) {
            setTimeout(() => {
              setAuthorized(true);
              if (transactionType == "subscription") {
                navigate("/profile/alromansiah-plus");
              } else {
                navigate("/profile/myWallet");
              }
            }, 10000);
          } else {
            setLoading(false);
            setState(true);
            setAuthorized(false);
          }
        } else if (response.data.data.status === "Captured") {
          setAuthorized(true);
          if (
            transactionType == "subscription" ||
            transactionType == "wallet"
          ) {
            if (transactionType == "subscription") {
              navigate("/profile/alromansiah-plus");
            } else {
              navigate("/profile/myWallet");
            }
          } else {
            setState(true);

            setLoading(false);
            setAuthorized(false);
          }
        }
      })
      .catch(() => {
        setAuthorized(false);
      });
  };

  useEffect(() => {
    setAuthorized(true);
    window.scrollTo(0, 0);
    checkPaymentStatus();
  }, []);

  function handleProceed() {
    var id = localStorage.getItem("orderId");
    history(generatePath("/order-track/:id", { id }));
  }
  function payAgain(paymentToken) {
    payAgain_checkout(token, paymentToken, localStorage.getItem("orderId"))
      .then((response) => {
        if (response.data.url !== "" && response.data.url !== null) {
          window.location.href = response.data.url;
        } else if (
          response.data.message == "warning" &&
          response.data.data == "This order has been paid."
        ) {
          setErrorMsgModel(true);
          setMsgAr("لقد تم دفع هذا الطلب بالفعل");
          setMsgEn("This order has been paid.");
          setPaymentLoad(false);
          Frames.init();
        } else if (response.data.message == "warning") {
          setErrorMsgModel(true);
          setMsgAr(" حدث فشل في عملية الدفع ، برجاء المحاولة مرة اخري");
          setMsgEn("Transaction has been failed,please try again");
          setPaymentLoad(false);
          Frames.init();
        } else {
          setState(true);
        }
      })
      .catch(function (err) {
        setErrorMsgModel(true);
        setMsgAr(" حدث فشل في عملية الدفع ، برجاء المحاولة مرة اخري");
        setMsgEn("Transaction has been failed,please try again");
        setPaymentLoad(false);
        Frames.init();
      });
  }
  return (
    <div>
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#E47100"} loading={loading} size={30} />
        </div>
      ) : (
        <>
          <div
            style={{ marginTop: "5em", marginBottom: "5em" }}
            className="container orderSuccess"
          >
            {state ? (
              <div className="shareForm ">
                {authorized ? (
                  <div
                    style={{ height: "10vw", marginBottom: "1.5em" }}
                    className="loader"
                  >
                    <DotLoader color={"#E47100"} size={80} />
                  </div>
                ) : (
                  <img style={{ marginBottom: "1em" }} src={success} />
                )}

                <p style={{ color: "rgb(99, 50, 53)" }}>
                  {authorized
                    ? t("payment.waiting")
                    : typeid === 3
                    ? t("home.thanksForDonation")
                    : paymentType === "order"
                    ? t("payment.success_payment")
                    : t("payment.success_charge")}
                </p>
                {authorized ? null : paymentType == "order" ? (
                  token ? (
                    <button
                      style={{ display: "inline-block" }}
                      onClick={handleProceed}
                    >
                      {" "}
                      {t("payment.track_order")}
                    </button>
                  ) : null
                ) : (
                  <Link to="/profile/myWallet">
                    <button style={{ display: "inline-block" }}>
                      {" "}
                      {t("profile.backWallet")}
                    </button>
                  </Link>
                )}
              </div>
            ) : null}
          </div>
        </>
      )}
      <Footer />
    </div>
  );
}

export default PaymentCheck;
