import React, {useEffect, useState} from 'react';
import "../Styles/Navbar.css"
import { IoChatbubblesOutline, IoCashOutline,IoBasketOutline,IoPeopleOutline } from "react-icons/io5";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { AiOutlineGift,AiOutlineShoppingCart } from "react-icons/ai";
import { GiKnifeFork } from 'react-icons/gi';
import { GoBook } from "react-icons/go";
import { BiShoppingBag } from "react-icons/bi";
import { useTranslation } from 'react-i18next';
import {BiMenuAltLeft} from "react-icons/bi";
import {BiMenuAltRight} from "react-icons/bi";
import {  Get_Basket ,countries,CustomerInfo} from '../api'
import ChangeType from "../Components/ChangeType";
import Logo from "../Images/logoAR.svg"
import LogoEn from "../Images/logoEn.svg"
import { NavLink,Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { AiOutlineUser } from "react-icons/ai";
import { Clear_cart } from '../api';
function Navbar(props) {
    const [toggle,setToggle]=useState(false);
    const { t, i18n } = useTranslation();
    const [country,setCountry]=useState(0);
let history=useNavigate()
    let navigate = useNavigate();
    const [lang,setLang]=useState("ar");
    const [count, setCount]= useState([]);
    const verified=  JSON.parse(localStorage.getItem("verified"));
    const name= localStorage.getItem("userName");
    const token= localStorage.getItem("token");
    const [changeModal,setChangeModal]=useState(false);
    const [countryChange,setCountryChange]=useState();
 
    // to display first name only
    if(name!==null){
    var firstName = name.split(' ').slice(0,1).join(' ');}

 
    //on changing language => takes "en" or "ar" as parameter
    //sets i18n language 
    //changes direction of document depending on language
    const changeLanguage = (e) => {
      props.handleChangeLang() //triggers loading in (App.js) to show loading on whole screen when changing language 
        var language = e.target.value;
         i18n.changeLanguage(language);
         localStorage.setItem("LANG",JSON.stringify(language ));
         setLang(language);
    
       if(language==="en"){
       document.getElementById("root").style.direction="ltr";
     
       }
       else{
         document.getElementById("root").style.direction="rtl";
       }

       
       };
  
       // first part is to store current country index in parent component (App.js) to be used in other components (displaying branches and adding address)
      //second part is to presist langunage and document direction and website name
    
       useEffect(() => {
       //  var index= document.getElementById("selectedCountry").value;

        props.setCountries(localStorage.getItem("country")? localStorage.getItem("country"):0)
    
     
          if(token){
            
    CustomerInfo(token).then((response) => {
    
      
     }).catch((err)=>{

      if(err.response.status === 401){
        //used to change cart icon's current value to 0
        props.setCartItem(0) 

  
      localStorage.removeItem("token");
      localStorage.removeItem("userPhone");
      localStorage.removeItem("userName");
      localStorage.removeItem("basket");
      //was used to store user's current step in cart process
      localStorage.removeItem("process");


      if(window.location.href.split('/')[3]=="companies-inquire" || window.location.href.split('/')[3]=="payment-success" || window.location.href.split('/')[3]=="profile" || window.location.href.split('/')[3]=="charge-wallet" || window.location.href.split('/')[3]=="order-track" || window.location.href.split('/')[3]=="address" || window.location.href.split('/')[3]=="cart" ){
      
        localStorage.setItem("prevScreen","home");

     history("/login")
   
      }
      else{
        window.location.reload();
      }
      
        }

         })

          }
    

const data=  JSON.parse(localStorage.getItem("LANG"));
i18n.changeLanguage(data);
setLang(data);


if(i18n.language==="en"){
  document.getElementById("root").style.direction="ltr";

  }
  else if(i18n.language==="ar"){
    document.getElementById("root").style.direction="rtl";
  
  }
  else{
   document.getElementById("root").style.direction="ltr";
  }

  if(i18n.language==="ar"){
   document.title="  مطاعم الرومانسية"
}else {
   document.title="Al Romansiah restaurants "

}
     },[lang,window.location.pathname]); 
 





      //on changing country if cart is not empty
    //a modal opens to confirm clearing the user's cart and choice of order type
    //if not => current country index is stored in parent component (App.js) to be used in other components (displaying branches and adding address) 
function handleChangeCountry(e){
  setToggle(false)
if(props.cartItems!==0 ){
  setCountryChange(e.target.value)

  setChangeModal(true);}

  else{
    props.setCountries(e.target.value)
    setCountry(e.target.value)
    localStorage.setItem("country",e.target.value)

  }
}

useEffect(() => {
  
  const data=  JSON.parse(localStorage.getItem("country")); 
  if(data){
    setCountry(data)
 

  }
  else{
    localStorage.setItem("country",0)
  }
  
  },[country]); 


//on confirming clearing cart from ChangeType modal component (found in /Components)
//current country index is stored in parent component (App.js) to be used in other components (displaying branches and adding address) 
//if cart is not empty it will be cleared then some items will be removed from locatStorage then user is redirected to home screen
//if cart is clear only order type will be removed from locatStorage then user is redirected to home screen
     function handleClear(){
 
      props.setCountries(countryChange)
      setCountry(countryChange)
      localStorage.setItem("country",countryChange)

      props.setLoading(true) //triggers loading in (App.js) to show loading on whole screen
     
           const token= window.localStorage.getItem('token');
       


if(  props.cartItems!==0 ){
           Clear_cart(props.cartId,token).then(response => {
            localStorage.removeItem("promo") //was used to store data that user entered in cart
            localStorage.removeItem("paymentMethod") //was used to store data that user entered in cart
            localStorage.setItem("process",0); //was used to store user's current step in cart, set to 0 to begin steps from the start
            localStorage.removeItem("typeId") //was used to store user's chosen order type
            localStorage.removeItem("offer");
            localStorage.removeItem("cardToken")
 //data stored in cartProcess2
            localStorage.removeItem("timeNow");
            localStorage.removeItem("cartMessage");
            localStorage.removeItem("orderDate");
            localStorage.removeItem("deliveryType")
  
            props.setCartChange(!props.cartChange) //triggers api call in (App.js) to rerender cart
            props.setLoading(false)
          history("/")
          }).catch(error => {      props.setLoading(false)})
    
        }
        else{
          localStorage.removeItem("typeId")
          props.setLoading(false)
          history("/")

        }
     
          }



   


    return (
      <>
      {changeModal? 
        <ChangeType text="change_country" handleClear={handleClear} setModal={setChangeModal}/>:null 
        }
      <div className='container-fluid fluid' >
<div className="navbar">
<div className="navbarTop">
<Link to="/"> 
<div className="navbarLogo">
<img style={i18n.language=="ar" ?null:{maxWidth:"210px"}} src={i18n.language=="ar"?Logo:LogoEn}/>

</div> </Link> 
<div className="navbarOptions">

<select value={country} id='selectedCountry' onChange={(e)=>handleChangeCountry(e)}> 

<option  value={0}>{t("nav.sa")} 🇸🇦</option>
<option value={1}>{t("nav.ba")} 🇧🇭</option>

</select>

<p className="navBorder1 mobileNavRemove">&nbsp;</p>
<select  value={lang} onChange={(e)=>changeLanguage(e)}>
<option value={"en"}> English </option>
<option value={"ar"}> العربية </option>
    </select>
   
 
<p className="navBorder1 mobileNavRemove">&nbsp;</p>
{ 
     !token || !verified?  //to check if user is not logged in the navbar will show a sign-in option instead of user profile
<Link to="/login"> 
<p> <span>{t("nav.signIn")}</span> &#160;<AiOutlineUser className='usrIcn' size={20}/></p> </Link>:
<Link to="/profile/myData"> 
<p> <span>{firstName}</span> &#160;<AiOutlineUser className='usrIcn' size={20}/></p> </Link>
 
}
<p className="navBorder1 mobileNavRemove">&nbsp;</p>
  
<a  onClick={()=>{
  
  localStorage.setItem("process",0) //used to redirect to first step in user cart in (CartProcess component found in /Pages)
props.setProcess(!props.process) //used to rerender (CartProcess component found in /Pages) to apply the above said step change
history("/cart")


}}> 


<p className=" mobileNavRemove"><span className='remove'>{t("nav.basket")}</span> <span className={props.cartItems==0?null:'count1' } data-count= {props.cartItems}><AiOutlineShoppingCart style={i18n.language=="ar"?{transform:"scaleX(-1)"}:{}} size={20}/></span></p> 

</a>
<p  style={{margin:"0 0.5em"}}></p>
<Link to="/order-now"> 
<button className='orderNow'>  {t("nav.order")} <span><GiKnifeFork size={20}/></span></button></Link>

<button onClick={()=> setToggle(!toggle)} className="togg ">{i18n.language=="ar"?<BiMenuAltLeft size={40}/>:<BiMenuAltRight size={40}/>}</button>

</div>
</div> 

<div style={i18n.language=="en" && toggle? {left:"auto"}:null} className={toggle?"nav2Mobile":"navbarBottom"} >

{toggle?
<>
<select value={country} id='selectedCountry'  onChange={(e)=>handleChangeCountry(e)
}> 

<option  value={0}>{t("nav.sa")} 🇸🇦</option>
<option value={1}>{t("nav.ba")} 🇧🇭</option>

</select>

<select  value={lang} onChange={(e)=>changeLanguage(e)}>
<option value={"ar"}> العربية </option>
<option value={"en"}> English </option>

    </select>
  
<a  onClick={()=>{setToggle(false)
props.setProcess(!props.process)
history("/cart")


}}> 
    <p ><span >{t("nav.basket")}</span> <span  className={props.cartItems==0?null:'count1 count2' }  data-count= {props.cartItems}><AiOutlineShoppingCart style={i18n.language=="ar"?{transform:"scaleX(-1)"}:{}} size={20}/></span></p> 
    </a>
</>
:null
}
  <NavLink  onClick={()=>setToggle(false)} to="/about-us">
  <p><span>{t("nav.ourStory")}</span><GoBook className="navIcon" size={20}/></p>

 
  </NavLink>
{/*
<p className="navBorder"> </p>

<NavLink  onClick={()=>setToggle(false)} to="/top-customers">
<p><span>{t("nav.topCustomers")}</span><svg className="navIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.55 9.18255V13.3242C3.55 14.8409 3.55 14.8409 4.98334 15.8076L8.925 18.0826C9.51667 18.4242 10.4833 18.4242 11.075 18.0826L15.0167 15.8076C16.45 14.8409 16.45 14.8409 16.45 13.3242V9.18255C16.45 7.66589 16.45 7.66589 15.0167 6.69922L11.075 4.42422C10.4833 4.08255 9.51667 4.08255 8.925 4.42422L4.98334 6.69922C3.55 7.66589 3.55 7.66589 3.55 9.18255Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.5833 6.35768V4.16602C14.5833 2.49935 13.75 1.66602 12.0833 1.66602H7.91666C6.25 1.66602 5.41666 2.49935 5.41666 4.16602V6.29935" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.525 9.15781L11 9.89948C11.075 10.0161 11.2417 10.1328 11.3667 10.1661L12.2167 10.3828C12.7417 10.5161 12.8833 10.9661 12.5417 11.3828L11.9833 12.0578C11.9 12.1661 11.8333 12.3578 11.8417 12.4911L11.8917 13.3661C11.925 13.9078 11.5417 14.1828 11.0417 13.9828L10.225 13.6578C10.1 13.6078 9.89167 13.6078 9.76667 13.6578L8.95 13.9828C8.45 14.1828 8.06667 13.8995 8.1 13.3661L8.15 12.4911C8.15834 12.3578 8.09167 12.1578 8.00834 12.0578L7.45 11.3828C7.10834 10.9661 7.25 10.5161 7.775 10.3828L8.625 10.1661C8.75834 10.1328 8.925 10.0078 8.99167 9.89948L9.46667 9.15781C9.76667 8.70781 10.2333 8.70781 10.525 9.15781Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> </p></NavLink>
*/}

{country==0?
<>
<p className="navBorder"></p>

<a  href='https://medhyaf.alromansiah.com/' target="_blank" >
<p><span> {t("nav.hospitable")}</span>
<svg className="navIcon hospitable" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.9167 16.6508H6.08333C5.73333 16.6508 5.34166 16.3758 5.225 16.0424L1.775 6.39245C1.28333 5.00912 1.85833 4.58411 3.04166 5.43411L6.29166 7.75912C6.83333 8.13412 7.45 7.94245 7.68333 7.33412L9.15 3.42578C9.61666 2.17578 10.3917 2.17578 10.8583 3.42578L12.325 7.33412C12.5583 7.94245 13.175 8.13412 13.7083 7.75912L16.7583 5.58411C18.0583 4.65078 18.6833 5.12578 18.15 6.63412L14.7833 16.0591C14.6583 16.3758 14.2667 16.6508 13.9167 16.6508Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.41666 19.168H14.5833" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.91666 12.5H12.0833" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

 </p> 
 </a>
 </>:null}

<p className="navBorder"></p>
<NavLink  onClick={()=>setToggle(false)} to="/awafi">
<p> <span>{t("nav.awafi")}</span> <AiOutlineGift size={20} className="navIcon"/> </p>
</NavLink> 

{country==0?
<>
 <p className="navBorder"></p>
 <NavLink  onClick={()=>setToggle(false)} to="/company-sales">
<p> <span>{t("nav.companiesSubscriptions")}</span> <IoCashOutline size={20} className="navIcon"/></p>

</NavLink>
</>
:null}

<p className="navBorder"></p>
<NavLink  onClick={()=>setToggle(false)} to="/branches">
<p> <span>{t("nav.branches")}</span> <HiOutlineLocationMarker size={20} className="navIcon"/></p>
</NavLink>
<p className="navBorder"></p>
<NavLink  onClick={()=>setToggle(false)} to="/contact-us">
<p>  <span>{t("nav.contactUs")}</span> <IoChatbubblesOutline size={20} className="navIcon"/></p>
</NavLink>
{/*
<p className="navBorder"></p>
<NavLink  onClick={()=>setToggle(false)} to="/client-care">
<p> <span>{t("nav.customerService")} </span><IoPeopleOutline size={20} className="navIcon"/></p></NavLink>
*/}

</div>
</div>

    </div></>);}
      export default Navbar; 