 

import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import disc from '../Images/percent.svg'
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import { create_order } from '../api'

import gift from "../Images/present.svg"
import party from "../Images/Party.svg"

function DiscountModal(props) {
  let history = useNavigate();
const { t, i18n } = useTranslation();
const [msgEn, setMsgEn]=useState();
const [msgAr, setMsgAr]=useState();
const [errorMsgModel, setErrorMsgModel]=useState(false);
const free= JSON.parse(window.localStorage.getItem('FREEMEALS')? window.localStorage.getItem('FREEMEALS') :  "[1]"  );
const totalMeals= free[0].quantity*free.length

const price= {
  total: localStorage.getItem("totalPrice"),
  discount: localStorage.getItem("discountPrice")
}

const currency= {ar: localStorage.getItem("currencyAr"),
en: localStorage.getItem("currencyEn")}
/*

 
function handleContinue(){

    const token= window.localStorage.getItem('token');
   
const data2=window.localStorage.getItem('ORDER');
    create_order(JSON.parse(data2),token).then(response => {
      console.log("test");




      console.log(JSON.parse(data2) );
  
      
      if(JSON.parse(data2).payment === "tap"){
        localStorage.setItem("orderId", response.data.data.orderId);
        if (response.data.data.url === null) {
      console.log("tap");
      //localStorage.setItem("menuRoute", "false");
      
        }
        else {
           window.location.href = response.data.data.url
        }
      }
      else {
   
       
      
        localStorage.setItem("orderId", response.data.data.orderId);
        console.log("tap else"); 
  props.setNav()
      }
      
    }).catch(error => {
      if (error.response.status === 422) {
        setErrorMsgModel(true);
        setMsgEn("Please wait, there is a request under process");
        setMsgAr("الرجاء الانتظار ، هناك طلب قيد التنفيذ");
    } })

 
} */

    return (
 
 
        <div  className="Modal">
        
          <div onClick={()=>{props.setLoading(false); props.setModal(false)}} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",padding:"0",width:"50vw ", maxWidth:"400px",minWidth:"337px"}}>
          
            <div >
              
             <div style={{textAlign:"center",flexDirection:"column"}} className='modalBody'>
             <div className=''>
          <img className="freeImg" src={gift} style={{height:"185px"}} />
    
        
    <div >
     <p className="gift-p1">{t("process.gift1")}</p> <span></span> 
     <img src={party} style={{height:"30px",width:"auto"}}/>
    <p className='gift-p2' style={{marginBottom:"1em"}}>    {localStorage.getItem('offerType')=="discount" || localStorage.getItem('offerType')=="freeMealsDiscount" || localStorage.getItem('offerType')=="discountCashBack"? t("process.offer") : t("process.cashBack")} {localStorage.getItem('offerType')=="freeMealsDiscount" || localStorage.getItem('offerType')=="freeMealsCashBack"? t("process.and") + `${totalMeals==1? `${t("process.gift2-1")}` :totalMeals==2? `${t("process.gift2-2")}` :totalMeals<=10? `${totalMeals} ${t("process.gift2-3")}`:`${totalMeals} ${t("process.gift2-4")}`}`:null} {localStorage.getItem('offerType')=="discountCashBack"? t("process.and") + t("process.cashBack"):null}! </p>
 
    {localStorage.getItem('offerType')=="discount" || localStorage.getItem('offerType')=="freeMealsDiscount" || localStorage.getItem('offerType')=="discountCashBack"?
    
    <div className="" >
    <div className='offerdetail' style={{marginBottom:"0"}}>
         
        <p className='offerAfter' >{price.discount}  {i18n.language=="ar"?currency.ar:currency.en}</p>
       
        <p className='offer-middle' >{t("process.instead")}</p>
        <p className='offerBefore'>{price.total}  {i18n.language=="ar"?currency.ar:currency.en}</p>
      
        </div> 
       </div>
:null}

{localStorage.getItem('offerType')=="freeMealsDiscount" || localStorage.getItem('offerType')=="freeMealsCashBack"?

<div style={{display:"inline-block"}} >
<ul>


{free && free.map((data, index)=>{
    return ( 
     <li className={i18n.language=="en"?"liEn":null} key={index}> <span style={{color:"#E47100"}}>{i18n.language=="ar"?`x${data.quantity}`: `${data.quantity}x`}</span> &nbsp;  {i18n.language=="ar"? data.arName:data.enName}</li>
    );})}

</ul>
</div>
:null}

    </div>
    
      
  
<div style={{minHeight:"100px",bottom:"70px"}} className={ errorMsgModel?'addAlert ':' addAlert alert-hidden '}  
  >

       <span style={{color:"brown"}}>{i18n.language=="en"? msgEn:msgAr}</span>


</div> 
   
          </div>
           <div className='modalBtns'>
{
  errorMsgModel?
<span style={{display:"block"}} className='err'>{i18n.language=="en"? msgEn:msgAr}</span>:null}
           <button className='confirm ' onClick={()=>{props.createBool==true? props.createOrder(localStorage.getItem("cardToken")): props.setModal(false)}}  >     {t("process.confirm")}</button>

        </div>
            </div>
          
     
            </div>
        
          </div>
        </div>
    

    );
}

export default DiscountModal;