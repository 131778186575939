
import "../Styles/CartProcess.css";

import { AiOutlinePlusCircle,AiOutlineDelete } from "react-icons/ai";
import CartItem from "../Components/CartItem";
import { Link } from "react-router-dom";
import food from "../Images/MainMenu_Icons/FoodImage.svg"
import { useTranslation } from 'react-i18next';
import {  Get_Basket,Delete_Item,Clear_cart,Edit_Quantity ,Add_to_cart,recommend } from '../api'
import { RiDeleteBin5Line } from "react-icons/ri";
import { useState ,useEffect} from "react";
import { CustomerInfo } from "../api";
import { getAnalytics, logEvent } from "firebase/analytics";
import { app } from "../Firebase/firebaseConfig";
import { BeatLoader } from "react-spinners";
import DeleteItem from "../Components/DeleteItem";
import ClearCart from "../Components/ClearCart";
import { Swiper, SwiperSlide } from "swiper/react";
import { IoIosAddCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { BackgroundImage } from 'react-image-and-background-image-fade'
  
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
function CartProcess1(props) { 
  const { t, i18n } = useTranslation();
   const [modal,setModal]=useState(false);
   const [clearModal,setClearModal]=useState(false);
  const [meals, setMeals]= useState([]);
  const [cartId, setCartId]= useState([]);

const[total,setTotal]=useState();
const[currency,setCurrency]=useState("");
const[calcTotal,setCalcTotal]=useState(false);
const [phone,setPhone]=useState("");
const [loading,setLoading]=useState(true);
const [recommendMeals,setRecommendMeals]=useState([]);

const [delData,setDelData]=useState({});
 const analytics = getAnalytics(app);
 const history=useNavigate()

 //CustomerInfo api call to get customer info for webengage and analytics
 //Get_Basket api call to get customer cart data
      useEffect(()=>{
      
  
  
        const token= window.localStorage.getItem('token');
        if(token){

      
        CustomerInfo(token).then((response) => {
  
          setPhone((response.data.data.phone).toString())
          
          })
        Get_Basket(token).then(response => {

          recommend(token,response.data.data.cart.branchId).then(response => {
            setRecommendMeals(response.data.data)
          }).catch(error => {

            setLoading(false)
  
          })

          if(parseInt(response.data.data.cart.typeId) ===  2){

            localStorage.setItem("addressId",response.data.data.cart.address.id)
          }
          localStorage.setItem("branchId",response.data.data.cart.branchId)


    // *all following locaStorage items are to be used in nexts steps of cart process*
          localStorage.setItem("currencyAr",response.data.data.cart.currencyAr)
          localStorage.setItem("currencyEn",response.data.data.cart.currencyEn)
          localStorage.setItem("typeId",response.data.data.cart.typeId)

          localStorage.setItem("delivery",JSON.stringify(response.data.data.cart.delivery)) 
         

          setMeals(response.data.data.cart.meals);
          setCartId(response.data.data.cart.id);
          window.localStorage.setItem('CartId',response.data.data.cart.id);
          setTotal(response.data.data.cart.totalPrice)
      
          setCurrency(response.data.data.cart);
          setLoading(false)
        }).catch(error => {
console.log(error.response.data.error)
          setLoading(false)

        })
        }
        else{
          setLoading(false)
        }
      },[calcTotal ])






      //on confirming remove item from cart in DeleteItem modal
      //Delete_Item api call takes cart id and item to be deleted id (delData.id is set when user clicks delete button on a CartItem component)
      function handleClose(){
      
       setLoading(true)
        const token= window.localStorage.getItem('token');


        let arr=[    //for webengage and analytics
          {
            name:    meals[delData.index].size.enName,
            quantity:    meals[delData.index].quantity.toString(),
            item_id:    meals[delData.index].id.toString()
            
          }
        ]

       Delete_Item(cartId,delData.id,token).then(response => {

          props.setCartChange(!props.cartChange)  //triggers api call in (App.js) to rerender cart

          localStorage.removeItem("paymentMethod")
          localStorage.removeItem("offer");
          localStorage.removeItem("promo") //remove value of promo code
    

    logEvent(analytics, 'removed_from_cart', {
      phone: phone,
      quantity: parseInt(delData.quantity),
      name: delData.name
    }); 





    logEvent(analytics, 'remove_from_cart', {
      items: arr,
     
    }); 



/*
    window.webengage.track("Removed From Cart", {
      phone: phone,
      quantity: parseInt(delData.quantity),
      name: delData.enName
    })*/

           setCalcTotal(!calcTotal); // to rerender cart total
      setLoading(false)
    
       }).catch(err => {

        if(err.response.status === 401){
          //used to change cart icon's current value to 0
    
          props.setCartItem(0) 
  
    
        localStorage.removeItem("token");
        localStorage.removeItem("userPhone");
        localStorage.removeItem("userName");
        localStorage.removeItem("basket");
        //was used to store user's current step in cart process
        localStorage.removeItem("process");
    
       history("/login")
     
        
        
          }
      })


       }




      //on confirming clear cart in ClearCart modal
//Clear_cart takes cart id 
       function handleClear(){

   setLoading(true)
        const token= window.localStorage.getItem('token');
        let arr=[]
 
        meals.map((data)=>     //for webengage and analytics
        arr.push(
        
        {  
        name: data.size.enName,
        quantity: data.quantity.toString(),
        item_id: data.id.toString()
        
        
        }
         
        )
        )

        Clear_cart(cartId,token).then(response => {

        

         
              logEvent(analytics, 'remove_from_cart', {
                items: arr,
               
              }); 
              localStorage.removeItem("cardToken")
              localStorage.removeItem("promo") //was used to store data that user entered in cart
              localStorage.removeItem("paymentMethod") //was used to store data that user entered in cart
              localStorage.setItem("process",0); //was used to store user's current step in cart, set to 0 to begin steps from the start
              localStorage.removeItem("typeId") //was used to store user's chosen order type
              if(localStorage.getItem("typeId") == 3) {
                localStorage.removeItem("formData")
              }
              localStorage.removeItem("offer");
        setMeals([]); //set meals array to empty
        setTotal(0)  //set cart total to 0
        props.setCartChange(!props.cartChange) //triggers api call in (App.js) to rerender cart
      props.setMeals([]) 
        setLoading(false)
       }).catch(err => {

        if(err.response.status === 401){
          //used to change cart icon's current value to 0
    
          props.setCartItem(0) 
  
    
        localStorage.removeItem("token");
        localStorage.removeItem("userPhone");
        localStorage.removeItem("userName");
        localStorage.removeItem("basket");
        //was used to store user's current step in cart process
        localStorage.removeItem("process");
    
       history("/login")
     
        
        
          }
      })


       }



 

      return (

      <div className="cartProcess">
        {loading?
      <div className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:

   
<div style={{marginTop:"2em"}} className="container-fluid fluid  ">
{modal? <DeleteItem handleCls={handleClose} setModal={setModal}/>:null}
 
{clearModal? <ClearCart handleClear={handleClear} setModal={setClearModal}/>:null}
    <div  >
    {meals.length==0 ||recommendMeals.length==0 ?null:
 <div className="spRecomend">
<p> {t("meals.recommended")}</p>
<Swiper 
navigation={true} 
dir={i18n.language=="ar"?"rtl":"ltr"}

key={i18n.language}
slidesPerView={"auto"}
spaceBetween={20}

modules={[Navigation]} 

  className={i18n.language=="ar"?"recommendSlider recommmendAr":"recommendSlider recommmendEn"}>
    

    
    
      
      {recommendMeals && recommendMeals.map((data,index)=>


        <SwiperSlide key={index}>  
           
           <div style={{margin:"0.5em 0",paddingBottom:"1em",cursor:"pointer"}} onClick={()=>history(`/meals/${data.id}`)}>
 
 <div className=" medyafMenu reccomendation recommendBasket">
 <BackgroundImage
        src={data.image}
       width="100%"
   style={{borderRadius:"8px 8px 0 0", maxHeight: "160px"}}
       className="bckg"
      />

 <div style={{padding:"0.3em"}}>
 <p className="recommend-name"> {i18n.language=="ar"? data.arName:data.enName}</p>
 <p className="recommend-price">{data.price} {i18n.language=="ar"?currency.currencyAr:currency.currencyEn}</p>

 </div>
 </div>
 </div>
         
             </SwiperSlide>
        
        
           
             
             )
        }
      
  
       
       
         
      </Swiper>
{/*
<div className="spRecomend-scroller">
<Recommendations/>
<Recommendations/>
<Recommendations/>
<Recommendations/> 
<Recommendations/>
    </div>*/}
    </div>}</div>
    <div className="cpMiddle-title">
    <p>{t("process.basket")}</p> 
    {meals.length==0?null:
    <div>
 <Link to="/menu"><button><span><AiOutlinePlusCircle/></span> {t("process.add")} </button></Link>
 
  

 
 <span className="space"></span>
 <button onClick={()=>setClearModal(true)}><span><AiOutlineDelete/></span> {t("process.clear")} </button>
 

 
 </div> }
   </div> 
   <div className="cpMiddle-cart">
   <div >
    
    {meals.length==0?null:
 <div className="container-fluid" style={{background:"white"}}>
  <div className="row cpMiddle-Nav">
 
    <div className="col-6">
    <p style={{textAlign:"start"}}>{t("process.meal")}</p>
    </div> 
    <div className="col">
    <p>{t("process.price")}</p>
    </div>
    <div className="col-2"> 
    <p>{t("process.quantity")}</p>
    </div>
    <div className="col">
     <p>{t("process.total")}</p>
    </div>
    <div className="col">
  
    </div>
    </div></div>
    
    }

 <div className="container-fluid">
 
{meals && meals.map((data,index)=>

  
<>
<CartItem setDelData={setDelData} index={index} setCartItem={props.setCartItem}  key={index} setModal={setModal} setCalcTotal={setCalcTotal} additional={ data.additional} arName={ data.product.arName} enName={ data.product.enName}  cartId={cartId} mealId={data.id} totalPrice={data.totalPrice} price={data.price}  name={i18n.language=="ar"? data.product.arName:data.product.enName}  image={data.image} extras={data.extras} description={i18n.language=="ar"? data.product.arDescription:data.product.enDescription} quantity={data.quantity} calcTotal={calcTotal}  currency= {i18n.language=="ar"? currency.currencyAr:currency.currencyEn}/>
<div  className="row cartHr" style={{backgroundColor:"#FBFBFB",}}>
   
<hr /></div>
</>

     )
}
</div>


</div>
 
   </div>
  
{meals.length==0?
<div className="emptyCart">
  <div className="emptyAlign">
<p>{t("basket.isEmpty")}</p>
<Link to="/order-now"><button><span><AiOutlinePlusCircle/></span> {t("process.add")} </button></Link>
 </div>
</div>:null}
 
{meals.length==0?null:
<>
   <div className="cpBottom">
   <p className={i18n.language=="ar"?"cpBottom-p1":"cpBottom-p1 cpBottom-p1En"}>{t("process.total")}</p>
   <div>
   <p className="cpBottom-p2">{total} </p>
   <p className="cpBottom-p3">{i18n.language=="en"?currency.currencyEn :currency.currencyAr}</p></div>
 
   </div>


   <div className="cartNext" style={{marginTop:"0"}}>
 <button onClick={()=>{ props.setNav(1);     localStorage.setItem("process",1);/*takes user to next step in cart process and stores it*/}} style={i18n.language=="ar"?{width:"170px"}:{width:"170px"}}>{t("process.next")}</button></div>
</>}

</div>}

      </div>
    
       
    );
  }
  
  export default CartProcess1;